<template>
  <section>
    <b-card v-loading="updateLoading">
      <div class="title">
        <p>{{ infoPageTitle }}</p>
      </div>

      <div>
        <b-button variant="outline-secondary" @click="goBackFilmTable()">
          回目錄
        </b-button>
      </div>
      <div>
        <b-form ref="form">
          <div v-for="item in items" :id="item.id" :key="item.id">
            <b-row :id="item.id" :key="item.id" ref="row">
              <b-col cols="12" class="mt-2">
                <p class="sub-title">
                  Distributor ID :
                  {{ pageIdVal == "edit" ? item.id : "Create New ID" }}
                </p>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Distributor Name"
                  label-for="distributor-name"
                  label-cols-md="3"
                >
                  <b-form-input
                    id="distributor-name"
                    label="Distributor Name"
                    v-model="item.distributor_name_en"
                    name="distributor_name_en"
                    :disabled="disableChange"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="發行商名稱"
                  label-for="publisher-name"
                  label-cols-md="3"
                >
                  <b-form-input
                    id="publisher-name"
                    label="發行商名稱"
                    v-model="item.distributor_name_zh_hk"
                    name="distributor_name_zh_hk"
                    :disabled="disableChange"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="發行商簡稱（10位)"
                  label-for="short-name"
                  label-cols-md="3"
                >
                  <b-form-input
                    id="short-name"
                    label="發行商簡稱（10位)"
                    v-model="item.distributor_code"
                    name="distributor_code"
                    maxlength="10"
                    :disabled="disableChange"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Remark"
                  label-for="remark"
                  label-cols-md="3"
                >
                  <b-form-textarea
                    id="remarks"
                    label="Remarks"
                    rows="3"
                    v-model="item.remarks"
                    name="remarks"
                    :disabled="disableChange"
                  />
                </b-form-group>
              </b-col>
              <b-col v-if="pageType == 'edit'">
                <div class="d-flex justify-content-end">
                  <b-button
                    @click="disableChange = false"
                    class="mr-1"
                    v-if="disableChange == true"
                    variant="outline-primary"
                    >修改</b-button
                  >
                  <b-button
                    class="mr-1"
                    @click="submitForm('save')"
                    v-if="disableChange == false"
                    variant="outline-primary"
                    >修正上方紀錄</b-button
                  >
                  <b-button
                    @click="submitForm('addEditSave')"
                    v-if="disableChange == false"
                    class="mr-1"
                    variant="outline-primary"
                    >修訂紀錄並紀錄舊名</b-button
                  >
                </div>
              </b-col>
            </b-row>
            <div v-if="items[0].id !== '' && items[0].old_record.length > 0">
              <b-col cols="12">
                <hr />
              </b-col>
              <b-row v-for="(item, index) in items[0].old_record" :key="index">
                <b-col cols="3">
                  <b-form-group
                    label=" 發行商名稱 (英文舊名)"
                    label-for="publisher-english-old"
                  >
                    <div class="text-wrap:wrap input-box-style">{{ item.name_en }}</div>
                    <!-- <b-form-input
                      id="publisher-english-old"
                      label=" 發行商名稱 (英文舊名)"
                      rows="3"
                      v-model="item.name_en"
                      name="name_en"
                      :disabled="true"
                    /> -->
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group
                    label="發行商名稱 (中文舊名)"
                    label-for="publisher-chinese-old"
                  >
                  <div class="text-wrap:wrap input-box-style">{{ item.name_zh_hk }}</div>
                    <!-- <b-form-input
                      id="publisher-chinese-old"
                      label="發行商名稱 (中文舊名)"
                      rows="3"
                      v-model="item.name_zh_hk"
                      name="name_zh_hk"
                      :disabled="true"
                    /> -->
                  </b-form-group>
                </b-col>
                <b-col cols="2">
                  <b-form-group label=" 發行商簡稱" label-for="short-name-old">
                    <div class="text-wrap:wrap input-box-style">{{ item.code }}</div>
                    <!-- <b-form-input
                      id="short-name-old"
                      label=" 發行商簡稱)"
                      rows="3"
                      v-model="item.code"
                      name="distributor_code"
                      :disabled="true"
                    /> -->
                  </b-form-group>
                </b-col>
                <b-col cols="2">
                  <b-form-group label="更改記錄日期" label-for="name-date">
                    <datePickerCom
                      :isDisabled="item.disabled"
                      :pickTimeVal.sync="item.change_date"
                      :inputWidth="'100%'"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="2" class="margin-auto">
                  <div
                    style="margin-top: 27px"
                    class="b-flex d-flex justify-content-center"
                  >
                    <feather-icon
                      v-if="
                        userInfo.role == 'Super Admin' &&
                        pageType === 'edit' &&
                        !item.disabled
                      "
                      v-b-tooltip.hover
                      title="Save"
                      size="25"
                      @click="historySave(item.id, index)"
                      icon="SaveIcon"
                      class="mr-3 cursor-pointer"
                      color="#0e72bc"
                    />
                    <feather-icon
                      v-if="
                        userInfo.role == 'Super Admin' &&
                        pageType === 'edit' &&
                        item.disabled
                      "
                      @click="editChange(index, 'edit')"
                      size="25"
                      v-b-tooltip.hover
                      title="Edit"
                      icon="EditIcon"
                      class="mr-3 cursor-pointer"
                      color="#0e72bc"
                    />
                    <feather-icon
                      v-if="
                        userInfo.role == 'Super Admin' &&
                        pageType === 'edit' &&
                        !item.disabled
                      "
                      @click="deleteDistributorHistory(item.id)"
                      size="25"
                      v-b-tooltip.hover
                      title="Delect"
                      icon="Trash2Icon"
                      color="red"
                      class="mr-3 cursor-pointer"
                    />
                    <span
                      v-if="!item.disabled"
                      @click="editChange(index, 'cancel')"
                      >取消</span
                    >
                  </div>
                </b-col>
              </b-row>
            </div>
            <b-row>
              <b-col cols="12">
                <hr />
              </b-col>
              <b-col
                cols="12"
                v-if="disableChange == false && pageType == 'edit'"
              >
                <b-button
                  type="button"
                  variant="outline-primary"
                  style="display: block; margin: 0 auto"
                  @click="editChange('test', 'all-cancel')"
                >
                  <span> 取消編輯 </span>
                </b-button>
              </b-col>
              <!-- submit and reset -->
              <b-col offset-md="5" v-if="type === 'create'">
                <b-button
                  variant="primary"
                  class="mr-1"
                  :disabled="disableChange"
                  @click="submitForm('create')"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </div>
    </b-card>
  </section>
</template>
<script>
import datePickerCom from "@/views/components/datePicker.vue";
import {
  createDistributor,
  editDistributor,
  deleteDistributorHistory,
  editDistributorHistory
} from "@/api/film-material-management/distributor";
import { getSelfProfile } from "@/api/login";
export default {
  name: "DistributorForm",
  components: {
    datePickerCom
  },
  props: {
    loading: {
      type: Boolean
    },
    title: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    },
    items: {
      type: Array,
      default: () => [
        {
          id: "",
          distributor_name_en: "",
          distributor_name_zh_hk: "",
          distributor_code: "",
          remarks: "",
          old_record: []
        }
      ]
    }
  },
  data() {
    return {
      dateFormat: "d-m-Y",
      showDelect: false,
      userInfo: {},
      disableChange: false,
      borderClass: "",
      pageId: "",
      pagePath: "",
      pageType: "",
      dateDefault: "today",
      formdata: {
        distributor_name_en: "",
        distributor_name_zh_hk: "",
        distributor_code: "",
        remarks: "",
        old_record: []
      }
    };
  },
  created() {
    this.pageType = this.$route.query.type;
    this.pageId = this.$route.query.id;
    this.pagePath = this.$route.path;
    if (this.pageType === "review") {
      this.disableChange = true;
    } else if (this.pageType === "edit") {
      this.disableChange = true;
      // this.dateDefault = this.items[0].old_record[0].change_date
      this.borderClass = "redborder";
    }
    this.getSelfProfile();
  },
  computed: {
    infoPageTitle() {
      console.log(this.pageType);
      if (this.pageType === "review") {
        return `${this.title}記錄`;
      } else if (this.pageType === "edit") {
        return `修訂${this.title}記錄`;
      } else {
        return `開立新${this.title}記錄`;
      }
    },
    pageIdVal() {
      if (this.pageType === "review") {
        return "edit";
      } else if (this.pageType === "edit") {
        return "edit";
      } else {
        return "Create New ID";
      }
    },
    updateLoading: {
      get() {
        return this.loading;
      },
      set(value) {
        this.$emit("update:loading", value);
      }
    }
  },
  methods: {
    editDistributorHistory(id, index) {
      const data = {
        name_en: this.items[0].old_record[index].name_en,
        name_zh_hk: this.items[0].old_record[index].name_zh_hk,
        change_date: this.items[0].old_record[index].change_date
      };
      editDistributorHistory(id, data)
        .then((res) => {
          this.$emit("reLoading");
          this.items[0].old_record[index].disabled = true;
          console.log(res);
        })
        .catch((error) => {
          this.updateLoading = false;
          console.log(error);
        });
    },
    deleteDistributorHistory(id) {
      this.updateLoading = true;
      deleteDistributorHistory(id)
        .then((res) => {
          this.$emit("reLoading");
          console.log(res);
        })
        .catch((error) => {
          this.updateLoading = false;
          console.log(error);
        });
    },
    historySave(id, index) {
      this.updateLoading = true;
      this.editDistributorHistory(id, index);
    },
    editChange(index, type) {
      if (type === "edit") {
        this.items[0].old_record[index].disabled = false;
      } else if (type === "cancel") {
        this.items[0].old_record[index].disabled = true;
        this.$emit("reLoading");
      } else if (type === "all-cancel") {
        this.disableChange = true;
        this.items[0].old_record.map((el) => {
          el.disabled = true;
        });
        this.$emit("reLoading");
      }
    },
    getSelfProfile() {
      getSelfProfile()
        .then((res) => {
          this.userInfo = res.data;
          console.log(this.userInfo);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goBackFilmTable() {
      this.$router.push({ name: "film-distributor" });
    },
    toEdit() {
      this.$router.push({
        path: this.pagePath,
        query: { type: "edit", id: this.pageId }
      });
      this.disableChange = false;
      this.borderClass = "redborder";
    },
    submitForm(val) {
      this.updateLoading = true;
      let reviseVal = "";
      if (val === "addEditSave") {
        reviseVal = 1;
      } else if (val === "save") {
        reviseVal = 0;
      }
      const data = {
        distributor_name_en: this.items[0].distributor_name_en,
        distributor_name_zh_hk: this.items[0].distributor_name_zh_hk,
        distributor_code: this.items[0].distributor_code,
        remarks: this.items[0].remarks,
        revise: reviseVal
      };
      if (this.type === "create") {
        createDistributor(data)
          .then((res) => {
            this.formdata.distributor_name_en = res.data.distributor_name_en;
            this.formdata.distributor_name_zh_hk =
              res.data.distributor_name_zh_hk;
            this.formdata.distributor_code = res.data.distributor_code;
            this.formdata.remarks = res.data.remarks;
            this.updateLoading = false;
            this.$router.push({
              name: "edit-film-distributor",
              query: {
                type: "review",
                id: res.data.id
              }
            });
          })
          .catch((error) => {
            console.log(error);
            this.updateLoading = false;
          });
      } else if (this.type === "edit") {
        editDistributor(this.items[0].id, data)
          .then((res) => {
            console.log(res.data);
            res.data.old_record.map((el) => {
              el.disabled = true;
            });
            this.items[0].old_record = res.data.old_record;
            console.log(res);
            this.updateLoading = false;
          })
          .catch((error) => {
            this.updateLoading = false;
            console.log(error);
          });
      }
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.sub-title {
  width: 100%;
  color: #0e72bc;
  font-size: 16px;
  font-weight: 500;
  margin: 20px 0;
}
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.line {
  height: 3px;
  color: #0e72bc;
}
.redborder {
  border-color: red;
}
</style>
